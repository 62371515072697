<template>
    <div class="search">
        <el-form :inline="true" :model="search" size="small" label-width="70px">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="任务分组">
                        <el-select v-model="search.taskGroup" placeholder="" style="width:100%">
                            <el-option label="储运销售公司" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="单位">
                        <el-input v-model="search.company" placeholder="请输入" clearable style="width:100%" />
                    </el-form-item>
                    <el-form-item label="标签分组">
                        <el-select v-model="search.tagGroup" placeholder="" style="width:100%">
                            <el-option label="全部" value="全部"></el-option>
                            <el-option label="低危人群分组" value="低危人群分组"></el-option>
                            <el-option label="中危人群分组" value="中危人群分组"></el-option>
                            <el-option label="高危人群分组" value="高危人群分组"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="搜索" label-width="40px">
                        <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select">
                            <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:80px">
                                <el-option label="名称" value="name"></el-option>
                                <el-option label="身份证" value="id"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24" style="padding-top: 10px">
                    <el-form-item label="报告筛选">
                        <el-select v-model="search.report" placeholder="" style="width:100%">
                            <el-option label="全部" value="1"></el-option>
                            <el-option label="2023年度" value="3"></el-option>
                            <el-option label="2022年度" value="2"></el-option>
                            <el-option label="2021年度" value="2021"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="建档时间">
                        <el-date-picker v-model="search.createdat" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="档案类型">
                        <el-select v-model="search.type" placeholder="" style="width:100%">
                            <el-option label="全部" value="1"></el-option>
                            <el-option label="健康体检" value="2"></el-option>
                            <el-option label="职业体检" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain @click="getData">查询</el-button>
                        <el-button type="" plain @click="reset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search: {}
        }
    }
}
</script>

<style lang="scss" scoped>
.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }
}

</style>
