const data = {
    table: {
        columns: [
            {title: '名称', field: 'name', width: ''},
            {title: '手机', field: 'phone', width: ''},
            {title: '身份证', field: 'sfz', width: ''},
            {title: '单位', field: 'company', width: ''},
            {title: '内容', field: 'content', width: ''},
            {title: '创建时间', field: 'createdAt', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
